
@import 'styles/variables/colors.scss';
@import 'styles/whitespace.scss';
@import 'styles/font.scss';

body {
  margin: 0;
  font-family: "Barlow", sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-logo {
  max-width: 400px;
}

.text-center {
  text-align: center;
}

.bg-primary {
  background-color: #E8E2E0;
}